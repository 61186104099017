exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-ads-template-js": () => import("./../../../src/templates/AdsTemplate.js" /* webpackChunkName: "component---src-templates-ads-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-component-page-template-js": () => import("./../../../src/templates/ComponentPageTemplate.js" /* webpackChunkName: "component---src-templates-component-page-template-js" */),
  "component---src-templates-faqs-template-js": () => import("./../../../src/templates/FaqsTemplate.js" /* webpackChunkName: "component---src-templates-faqs-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-testimonials-index-template-js": () => import("./../../../src/templates/TestimonialsIndexTemplate.js" /* webpackChunkName: "component---src-templates-testimonials-index-template-js" */),
  "component---src-templates-testimonials-template-js": () => import("./../../../src/templates/TestimonialsTemplate.js" /* webpackChunkName: "component---src-templates-testimonials-template-js" */)
}

